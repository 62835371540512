import instance from "base_url";
import React from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Label,
  FormGroup,
  Button,
} from "reactstrap";
import { useState, useEffect } from "react";
import Autocomplete from "components/Common/Autocomplete";

const FiltersOffcanvas = ({
  isOpen,
  toggle,
  familyStatus,
  setFamilyStatus,
  setIdentityPlace,
  healthCondition,
  setHealthCondition,
  setProvince,
  district,
  setDistrict,
  area,
  setArea,
  applyFilters,
  t,
  sehirData,
  mahalleData,
  ilceData,
  setSehirData,
  setMahalleData,
  setIlceData,
  getIlceData,
  getMahalleData
}) => {


  console.log("sehirData", sehirData);
  return (
    <Offcanvas isOpen={isOpen} toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>{t("Table filters")}</OffcanvasHeader>
      <OffcanvasBody>
        <FormGroup>
          <Label for="familyStatus">{t("Family Status")}</Label>
          <Input
            type="select"
            id="familyStatus"
            value={familyStatus}
            onChange={(e) => setFamilyStatus(e.target.value)}
          >
            <option value="">{t("Select Family Status")}</option>
            <option value="completed">{t("Completed")}</option>
            <option value="incomplete">{t("Incomplete")}</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="identityPlace">{t("Identity Place")}</Label>
          <Autocomplete
            name={t("Identity Place")}
            searchParam="sehir_title"
            placeholder={t("Select Identity Place")}
            basePlaceholder={t("Select Identity Place")}
            list={sehirData}
            selectedObject={(value) => {
              setIdentityPlace(value?.sehir_title || "");
            }}
            isError={false}
          />
        </FormGroup>
        <FormGroup>
          <Label for="conditionFilter">
            {t("Health/Disability Condition")}
          </Label>
          <Input
            type="select"
            id="conditionFilter"
            value={healthCondition}
            onChange={(e) => setHealthCondition(e.target.value)}
          >
            <option value="">{t("Select Condition")}</option>
            <option value="health">{t("Health")}</option>
            <option value="disability">{t("Disability")}</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="province">{t("Province")}</Label>
          <Autocomplete
            name={t("Province")}
            searchParam="sehir_title"
            placeholder={t("Select Province")}
            basePlaceholder={t("Select Province")}
            list={sehirData}
            selectedObject={(value) => {
              setProvince(value?.sehir_title || "");
              getIlceData(value?.name);
            }}
            isError={false}
          />
          {/* <Input
            type="text"
            id="province"
            value={province}
            onChange={(e) => setProvince(e.target.value)}
            placeholder={t("Enter Province")}
          /> */}
        </FormGroup>

        <FormGroup>
          <Label for="district">{t("District")}</Label>
          <Input
            type="text"
            id="district"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            placeholder={t("Enter District")}
          />
        </FormGroup>

        <FormGroup>
          <Label for="area">{t("Area")}</Label>
          <Input
            type="text"
            id="area"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            placeholder={t("Enter Area")}
          />
        </FormGroup>

        <Button color="primary" onClick={applyFilters} className="mt-3">
          {t("Apply Filters")}
        </Button>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default FiltersOffcanvas;
