import React from 'react';
import { useTranslation } from 'react-i18next';

 const CalendarTranslations = () => {
  const { t } = useTranslation();

  const calendarTranslations = {
    shortDays: [
      t('calendar.shortDays.0'), 
      t('calendar.shortDays.1'),  
      t('calendar.shortDays.2'),  
      t('calendar.shortDays.3'), 
      t('calendar.shortDays.4'),  
      t('calendar.shortDays.5'), 
      t('calendar.shortDays.6')   
    ],
    longhand: [
      t('calendar.days.0'),  
      t('calendar.days.1'),  
      t('calendar.days.2'),  
      t('calendar.days.3'),  
      t('calendar.days.4'),  
      t('calendar.days.5'),  
      t('calendar.days.6')   
    ],
    months: [
      t('calendar.months.0'),  
      t('calendar.months.1'), 
      t('calendar.months.2'), 
      t('calendar.months.3'),  
      t('calendar.months.4'),  
      t('calendar.months.5'),  
      t('calendar.months.6'),  
      t('calendar.months.7'),  
      t('calendar.months.8'),  
      t('calendar.months.9'),  
      t('calendar.months.10'),    
      t('calendar.months.11')   
    ]
  };

  return calendarTranslations;
};

export default CalendarTranslations;
